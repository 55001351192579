export var clap ={
    "id": "1fc7d83d-d7a6-47ba-9876-09d616e41ac7",
    "sound": "clap.wav",
    "group": "Deep_House_CLAP",
    "maxTime": 125,
    autoProduce:[],
    "frames": [
       
    ],
    "on": false
}