import React, { useEffect, useRef } from 'react'
import { ZotUtil } from '../util/ZotUtil';

export default function TimelinePlayerCursor({ gridSize = 20, MouseDown = null, MouseMove = null, MouseUp = null, timeLineCursor = 0 }) {
    const draggableRef = useRef(null);
    let isDragging = false;
    let startX = 0;
    let initialLeft = 0;


    useEffect(() => {
        const handleMouseDown = (e) => {
            isDragging = true;
            startX = e.clientX;
            initialLeft = draggableRef.current.offsetLeft;
            draggableRef.current.style.cursor = 'grabbing';

            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
            if (MouseDown !== null) {
                MouseDown()
            }
            // setIsSceneDragPlay(true)
            // setIsPlaying(false)
        };

        const handleMouseMove = (e) => {
            console.log(draggableRef.current.scrollLeft)
            if (!isDragging) return;
            const deltaX = e.clientX - startX;
            let newLeft = initialLeft + deltaX;
            const snapIncrement = gridSize; // Snap increment in pixels
            const divWidth = 4; // Width of the draggable div

            // Snap to nearest snapIncrement based on divWidth
            newLeft = Math.round(newLeft / snapIncrement) * snapIncrement;

            // Ensure the div doesn't go out of bounds
            const containerWidth = draggableRef.current.parentNode.offsetWidth - divWidth;

            newLeft = Math.max(0, Math.min(newLeft, containerWidth));
            draggableRef.current.style.left = `${newLeft}px`
            window.scrollBy({ left: newLeft, behavior: 'smooth' });
            if (MouseMove !== null) {
                // var pxToF = ZotUtil.pixelsToFrames(newLeft)
                // var seconds = ZotUtil.frameToSecond(pxToF)
                MouseMove(newLeft)
            }


        };

        const handleMouseUp = () => {
            if (MouseUp !== null) {
                MouseUp()
            }
            isDragging = false;
            draggableRef.current.style.cursor = 'grab';
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        const draggable = draggableRef.current;
        draggable.addEventListener('mousedown', handleMouseDown);

        return () => {
            draggable.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);
    return (
        <>
            <div id='time-line-cursor' onScroll={e=>alert("")} ref={draggableRef} className='time-line-cursor' ></div>
        </>
    )
}
