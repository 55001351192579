import React, { useEffect, useRef, useState } from 'react'
import './css/t-anime-canvas.scss'
import './css/timeline.scss'
import './css/packs.scss'
import './css/song.scss'
// import CircularSlider from '@fseehawer/react-circular-slider';
import TimelineFramePlayer from './TimelinePlayer'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { v4 as uuidv4 } from 'uuid';
import { Slider } from 'primereact/slider';

import { Accordion, AccordionTab } from 'primereact/accordion';

import { Dropdown } from 'primereact/dropdown';
import { ZotUtil } from '../util/ZotUtil';
import { kick } from './sound/kick';
import { clap } from './sound/clap';
import { hat } from './sound/hat';
import { rhodes } from './sound/rhodes';
import AutoDaw from './AutoDaw';
import { Dialog } from 'primereact/dialog'
import { bassline } from './sound/bassline'
// import shiftChord, { notesToChord } from './AuthChords'

const n = new window.music21.note.Note('F#');


//AM7/B-FM7/A-E/B-E/B-AM7/B-FM7/A-E/B-E/B
export default function ({ setKeyframes, keyframes = [] }) {

  // // Example usage
  // const eMajorChord = 'E major'; // Chord name
  // const dMinorChord = 'D minor'; // Chord name

  // const eMajorShiftedNotes = shiftChord(eMajorChord, 5); // Shift E major chord up by 5 semitones
  // const dMinorShiftedNotes = shiftChord(dMinorChord, 5); // Shift D minor chord up by 5 semitones

  // const eMajorShiftedChord = notesToChord(eMajorShiftedNotes); // Convert shifted notes back to chord
  // const dMinorShiftedChord = notesToChord(dMinorShiftedNotes); // Convert shifted notes back to chord

  // console.log(`E Major Chord Shifted Notes: ${eMajorShiftedNotes.join(', ')}`);
  // console.log(`E Major Chord Shifted: ${eMajorShiftedChord}`);
  // console.log(`D Minor Chord Shifted Notes: ${dMinorShiftedNotes.join(', ')}`);
  // console.log(`D Minor Chord Shifted: ${dMinorShiftedChord}`);


  const [showPianoRoll, setShowPianoRoll] = useState(false)
  const [timelineCursor, setTimeLineCursor] = useState(0)
  const [showPacks, setShowPacks] = useState({ show: false, selected_id: null })

  var [keyframes, setKeyframes] = useState([])
  var [selectedIndex, setSelectedIndex] = useState(null)
  var keys = ZotUtil.generatePianoKeyAccess()


  var packs = [
    { name: "Hkick1", "path": "kick.wav", group: "Deep_House_KICK" },
    { name: "HHat1", "path": "hat.wav", group: "Deep_House_HAT" },
    { name: "HClap", "path": "clap.wav", group: "Deep_House_CLAP" },
    { name: "Piano", "path": "paino.wav", group: "Deep_House_Piano" },
    { name: "Rhodes", "path": "rhodes.wav", group: "Deep_House_Piano" },
    { name: "Bassline", "path": "bassline.wav", group: "Deep_House_BASS" },
  ]

  var groupPacks = ZotUtil.groupPacksBy(packs, "group")



  var [projectList, setProjectList] = useState([])

  var soundList = [
    { name: "kick", value: "kick.wav" },
    { name: "clap", value: "clap.wav" },
    { name: "piano", value: "piano.wav" },
    { name: "rhodes", value: "rhodes.wav" },
    { name: "string", value: "string.wav" },
    { name: "hat", value: "hat.wav" },
    { name: "hat2", value: "hat2.wav" },
    { name: "bassline", value: "bassline.wav" }
  ]







  useEffect(() => {
    if (selectedIndex !== null) {
      projectList[selectedIndex] = keyframes[0]
      setProjectList([...projectList])
    }
    console.log(keyframes)
  }, [setKeyframes, keyframes])



  var [daw, currentTime] = AutoDaw()


  useEffect(() => {
    daw.addSound("rhodes.wav")
    daw.addSound("kick.wav")
    daw.addSound("clap.wav")
    daw.addSound("hat.wav")
    daw.addSound("bassline.wav")
  

    // for (let index = 0; index <20; index++) {
    projectList.push(rhodes)
    projectList.push(bassline)
    // projectList.push(JSON.parse(JSON.stringify(rhodes)))
     projectList.push(kick)
    // }
    projectList.push(clap)
    projectList.push(hat)
    setProjectList([...projectList])

  }, [])

  var ProduceOrder = [
    { name: "First", value: "fist" },
    { name: "Second", value: "second" },
    { name: "Third", value: "third" },
    { name: "Fourth", value: "fourth" },
    { name: "Fifth", value: "fifth" },
    { name: "Sixth", value: "sixth" },
    { name: "Seventh", value: "seventh" },
    { name: "Eighth", value: "eighth" }
  ];


  const [value, setValue] = useState(115);
  const [value1, setValue1] = useState(0.0);


  return (

    <div className="music-container"  id='music-container'>
      <>


        <Dialog visible={false} header={<div>
          Producer Lab
          <Button label='Play All' onClick={() => {
            daw.play(0, projectList)
          }} />
          <Button label='generate'
            onClick={e => {


              function shuffleArray(array) {
                for (let i = array.length - 1; i > 0; i--) {
                  // Generate a random index
                  const j = Math.floor(Math.random() * (i + 1));

                  // Swap the elements at indices i and j
                  [array[i], array[j]] = [array[j], array[i]];
                }
                return array;
              }

              // Example usage
              const myArray = [0, 0, 0, 1, 1, 0, 0, 1, 0, 0, 1, 1];
              const shuffledArray = shuffleArray(myArray);

              projectList = projectList.map(e => {
                e.autoProduce = shuffledArray
                return e
              })
              setProjectList([...projectList])
            }} />
        </div>} style={{ height: "100vh", width: "100%" }}>

          <div className="song-lab">
            <div className="layers">
              {projectList.map((e, projInd) => (<div className="layer">{e.sound}</div>))}
            </div>
            <div className="patterns">
              {projectList.map((e, projInd) => (


                <div className="pattern">
                  {e?.autoProduce?.map((patt, pattInd) => (
                    <div
                      key={pattInd}
                      onClick={(aa) => {
                        aa.preventDefault();
                        aa.stopPropagation();

                        // Update the state immutably
                        setProjectList(prevList =>
                          prevList.map((proj, index) => {
                            if (index === projInd) {
                              // Create a new object for the updated project
                              return {
                                ...proj,
                                autoProduce: proj.autoProduce.map((p, pIndex) =>
                                  pIndex === pattInd ? (p === 0 ? 1 : 0) : p
                                )
                              };
                            }
                            return proj;
                          })
                        );
                      }}
                      className={`block active-${patt}`}
                    ></div>
                  ))}
                </div>

              ))}
            </div>
          </div>

        </Dialog>
        <Dialog header="PACKS LAB" style={{ height: "100vh", width: 500 }}
          visible={showPacks.show}
          onHide={e => {
            setShowPacks({ show: false, selected_id: null })
          }}
        >
          <Accordion activeIndex={0} className='music-packs'>
            {Object.keys(groupPacks).map((groupName, index) => {
              var packList = groupPacks[groupName];
              return (

                <AccordionTab key={index} header={groupName}>
                  <ol className='packs-list'>
                    {packList.map(e => <li onClick={() => {
                      var sound = {
                        id: uuidv4(),
                        sound: e.path,
                        title: e.title,
                        group: groupName,
                        maxTime: 0,
                        frames: [

                        ],
                        on: false,
                      }
                      projectList.push(sound)
                      setProjectList([...projectList])
                      daw.addSound(e.path)
                    }}>{e.name}</li>)}
                  </ol>
                </AccordionTab>


              )
            })}
          </Accordion>
        </Dialog>

        <DataTable
          header={<>
            <Slider max={200} value={value} onChange={(e) => {
              setValue(e.value)
              daw.setNewTempo(parseInt(e.value))
            }} className="w-14rem" />
            {value}
            <br />
            <br />
            <Slider min={0} max={100} value={value1} onChange={(e) => {
              function normalize(value, min = 0, max = 100) {
                if (value < min || value > max) {
                  throw new Error("Value is out of the specified range");
                }
                return (value - min) / (max - min);
              }
              setValue1(e.value)
              daw.setNewSwing(normalize(parseInt(e.value)))
            }} className="w-14rem" />
            {value1}
            <br />
            <Button label='Add Sound' onClick={() => {
              setShowPacks({ show: true, selected_id: null })
              // daw.play(50, projectList)

            }} />
            <Button label='Play All' onClick={() => {
              daw.play(0, projectList)
            }} />





          </>}
          className='w-full' value={projectList} >
          <Column field="Active" header="Active" body={e => e.on ? <Button icon="pi pi-trash" className="p-button-rounded p-button-success mr-2 mb-2" /> : <Button icon="pi pi-times" className="p-button-rounded  p-button-danger mr-2 mb-2" />}></Column>
          {/* <Column field="Volume" header="Volume" body={(e, row) => 
          <CircularSlider
            width={50}
            max={5}
            min={0}
            initialValue={1.0}
            progressSize={2}
            knobSize={15}
            trackSize={2}
            verticalOffset="5px"
            labelFontSize='0'
            valueFontSize="30px"
            onChange={value => {
              console.log(value);
              projectList[row.rowIndex].volume = value
              setProjectList([...projectList])
            }}
          />}></Column> */}
          <Column field="sound" header="Sound"
            body={(e, row) => <Dropdown
              value={e.sound}
              onChange={change => {
                projectList[row.rowIndex].sound = change.value
                setProjectList([...projectList])

              }}
              options={soundList} optionLabel="name" optionValue='value'
              placeholder="Select a Sound" className="w-full md:w-14rem" />}
          ></Column>
          <Column header="Actions"
            body={(e, row) => <>
              <Button
                onClick={e => {
                  setShowPianoRoll(true)
                  setSelectedIndex(row.rowIndex)
                  setKeyframes([
                    projectList[row.rowIndex]
                  ])
                }}
                icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2 mb-2" />
              <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2 mb-2" />
            </>}
          ></Column>
        </DataTable>

        {showPianoRoll === true && <TimelineFramePlayer
          daw={daw}
          projectList={projectList}
          setProjectList={setProjectList}
          timelineCursor={timelineCursor}
          currentTime={currentTime}
          setTimeLineCursor={setTimeLineCursor}
          onSingleNote={(index, roll) => {

            var note = null
            if (index === "roll") {
              note = roll;
            } else {
              note = keys[index];
            }
          }}
          showPianoRoll={showPianoRoll} setShowPianoRoll={setShowPianoRoll} keyframes={keyframes} setKeyframes={setKeyframes} />}

      </>
    </div>

  )
}
