import React, { useRef, useState } from 'react'
import * as Tone from 'tone';
import rhodes from './packs/rhodes.wav'
import kick from './packs/kick.wav'
import clap from './packs/clap.wav'
import hat from './packs/hat.wav'
import piano from './packs/piano.wav'
import bassline from './packs/bassline.wav'
import { useInterval } from "react-interval-hook";
import { ZotUtil } from '../util/ZotUtil';
import { downloadWav } from '../util/WebMToWav';
//
// var r=require("webm-to-wav-converter")
var s = {
    "kick.wav": kick,
    "rhodes.wav": rhodes,
    "clap.wav": clap,
    "hat.wav": hat,
    "paino.wav": rhodes,
    "bassline.wav": bassline
}
export default function AutoDaw() {
    const sourceNode = useRef();
    const [studio, setStudio] = useState({});
    const [isPlaying, setIsPlaying] = useState(false);
    const [tempo, setTempo] = useState(115);
    const [stopPlayingTime, setStopPlayingTime] = useState(0);
    const [audioCtx, setAudioCtx] = useState(null);
    var [countBars, setCountBars] = useState(0);
    const [currentTime, setCurrentTime] = useState();
    const [startTime, setStartTime] = useState(Date.now());
    var [startStep, setStartStep] = useState(0);
    var [partDispose, setPartDispose] = useState([]);

    // Store the start time when the script starts
    const bars = 4; // Number of bars in your sequence
    // Function to get the current time in beats
    function getCurrentBeats(startTime) {
        const millisecondsPerBeat = 60000 / tempo;
        const now = Date.now();
        const elapsedTime = now - startTime;
        return elapsedTime / millisecondsPerBeat;
    }
    function getCurrentStep1(startTime) {
        const totalBeats = getCurrentBeats(startTime);

        const totalSteps = (totalBeats * 16);
        return Math.floor(totalSteps / bars) // Ensures step index is within 0-15 range
    }

    // Function to get the current step in a 16-step sequencer
    function getCurrentStep(startTime, startStep) {
        const totalBeats = getCurrentBeats(startTime);
        const totalSteps = (totalBeats * 16);
        var c = Math.floor(totalSteps / bars)
        return startStep + c;; // Current step in the sequence
    }



    const { start, stop, isActive } = useInterval(
        () => {
            const beats = getCurrentStep(startTime, startStep);
            if (Math.floor(beats) >= stopPlayingTime) {
                // stop();
            } else {
                setCurrentTime(Math.floor(beats))
                try {
                    document.getElementById('time-line-cursor').style.left = `${beats * 20}px`
                } catch (error) {
                    // console.log("alas")
                }
                // console.log(`Current time in beats: ${Math.floor(beats)}`, stopPlayingTime);
            }
        },
        0,
        {
            autoStart: false,
            immediate: false,
            onFinish: () => {
                console.log("Callback when timer is stopped");
            }
        }
    );

    class Studio {
        addSound(name, path) {
            console.log(s[name])
            var part = new Tone.Part
            var sound = new Tone.Sampler(
                { C5: s[name] },
                {
                    onload: () => {
                        studio[name].loaded = true;
                        setStudio({ ...studio })
                    }
                }
            ).toDestination();
            studio[name] = { sound: sound, loaded: false, path: path, part: part };

            setStudio({ ...studio })
        }
        removeSound(name) {
            studio[name].sound.dispose()
            delete studio[name]
            setStudio({ ...studio })
        }

        playSingleNote(name, note) {
            try {
                if (!studio[name].loaded) {
                    console.log("sound not loaded")
                    return;
                }
                studio[name].sound.triggerAttackRelease(note, 0.1);
                Tone.Transport.start()
                Tone.start()
            } catch (error) {
                console.log("wrong sound name",error)
            }
        }

        async stop() {
            await Tone.Transport.stop()
            await Tone.Transport.cancel()
        }




        async play(seek = 0, sounds = []) {
            setCountBars(0)
            // const recorder = new Tone.Recorder();

            const actx = Tone.context;
            const dest = actx.createMediaStreamDestination();
            // const recorder = new MediaRecorder(dest.stream);
            const recorder = new Tone.Recorder();
            partDispose.forEach(part => {
                part.dispose();
            })



            Tone.Transport.bpm.value = tempo
            Tone.Transport.timeSignature = 4

            // Tone.Transport.swing=0.2
            await Tone.Transport.stop()
            await Tone.Transport.cancel()
            var times = []
            var volumesBefore = {}
            recorder.start()
            sounds.forEach(project => {
                var chords = [];
                var name = project.sound
                times.push(project?.maxTime || 0)
                // var patterns = project?.autoProduce || []
                var patterns =[1]

                patterns.forEach((isActive, index) => {
                    if (index === 0) {
                        if (isActive == 1) {
                            chords.push(...project.frames)
                        } else {
                            chords.push(...ZotUtil.shiftNoteTiming([{ time: "0:0:0", duration: "8:0:0", note: "A0", volume: -1000 }], index))
                        }
                    } else {
                        if (isActive == 1) {
                            chords.push(...ZotUtil.shiftNoteTiming(project.frames, index))
                        } else {
                            // chords.push({ time: "0:0:0", duration: "8:0:0", note: "A0", volume: -1000 },)
                            chords.push(...ZotUtil.shiftNoteTiming([{ time: "0:0:0", duration: "8:0:0", note: "A0", volume: -1000 }], index))
                        }
                    }
                })
                // console.log(chords)
                try {
                    if (!studio[name].loaded) {
                        console.log("sound not loaded")
                        return;
                    }
                    // console.log(project?.volume || 1)
                    // studio[name].sound.connect(dest)
                    var so = studio[name].sound;
                    studio[name].sound.connect(recorder)
                    var vol = project?.volume || 1
                    studio[name].sound.volume.value = vol
                    volumesBefore[name] = vol
                    // studio[name].sound.volume.value =-10000

                    var p = new Tone.Part((time, value) => {
                        if (value?.volume) {
                            so.triggerAttackRelease(value.note, value.duration, time, 0);
                        }
                        else {
                            so.triggerAttackRelease(value.note, value.duration, time);
                        }
                        // var vol = project?.volume || 1
                        // studio[name].sound.volume.value = vol
                    }, chords)
                    chords = []
                    if (name === "rhodes.wav") {
                        // p.humanize = '0:0:0.9'
                        //   p.probability

                    }
                    partDispose.push(p)
                    setPartDispose([...partDispose])
                    if (seek >= 0) {
                        var stepToToneJsTime = ZotUtil.stepsToneJsTime(seek)
                        var tonejsTimeToSeconds = Tone.Time(stepToToneJsTime)
                        p.start(0, tonejsTimeToSeconds)
                    }
                    else {
                        p.start(0)
                    }
                } catch (error) {
                    console.log("wrong sound name", error)
                }
            });
            if (times.length === 0) return;

            // Tone.Transport.loop = true;
            // Tone.Transport.loopStart = 0;
            // Tone.Transport.loopEnd = "8m"; // Loop every 8 bars
            var patternsCount = 0;

            //give a little boost to the lows
            var freeverb = new Tone.Reverb().toDestination();
            freeverb.decay = 1;

            var meter = new Tone.Meter();
            // var mic = new Tone.UserMedia().open();
            //connect mic to the meter
            studio["clap.wav"].sound.connect(meter);
            //the current level of the mic input in decibels
            var level = meter.getLevel();
            //routing synth through the reverb
            // studio["clap.wav"].sound.connect(freeverb);




            const chunks = [];
            sourceNode.current = actx.createMediaStreamDestination();
            Tone.Transport.on("loop", (t) => {
                console.log(t)
            })
            //route everything through the filter
            //and compressor before going to the speakers
            Tone.start().then(async () => {
                // recorder.start();
                const loop = new Tone.Loop((time) => {
                    console.log("Loop executed at", time);
                    setStartTime(Date.now())
                    setStartStep(seek)

                    patternsCount += 1;

                    countBars += 1
                    setCountBars(countBars)
                }, "8m").start(0);
                partDispose.push(loop)
                setPartDispose([...partDispose])
                const maxNumber = Math.max(...times);
                await setStopPlayingTime(maxNumber)
                await Tone.Transport.start()
                await setIsPlaying(true)
                await setStartTime(Date.now())
                await setStartStep(seek)
                if (isActive) {
                    await start()
                }

                setTimeout(async () => {
                    // recorder.
                    // the recorded audio is returned as a blob
                    // const recording = await recorder.stop();
                    const recording = await recorder.stop();
                    // recorder.stop();
                    // recorder.ondataavailable = evt => {
                    //     // console.log(evt)
                    //     // downloadWav("final-audio", [evt.data], false);
                    // }
                    // download the recording by creating an anchor element and blob url
                    // const url = URL.createObjectURL(recording);
                    // const anchor = document.createElement("a");
                    // anchor.download = "recording.webm";
                    // anchor.href = url;
                    // anchor.click();
                    // downloadWav([recording.stream()], false);
                }, 20000);
            })
        }

        setNewTempo(val) {
            Tone.Transport.bpm.value = val;
            setTempo(val)
        }
        setNewSwing(val) {
            Tone.Transport.swing = val;
        }
        meter() {
            return audioCtx === null ? null : [audioCtx, [sourceNode.current]]
        }
    }
    var daw = new Studio()
    return [daw, currentTime,]
}
