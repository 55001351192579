import React from 'react';
import { Resizable } from 're-resizable';
import Draggable from 'react-draggable';
import { useEffect, useRef, useState } from "react";

import Selecto from "react-selecto";
import { Scene } from "react-scenejs";
import { ZotUtil } from '../util/ZotUtil';
import { Button } from 'primereact/button';
import TimelinePlayerCursor from './TimelinePlayerCursor';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import piano from '../assets/piano.png';
import Moveable from "react-moveable";
import { generateProgression, generateProgressionWithTiming } from './chordsGen';
import { generateSequence } from './chordGen2';
import PlayerSoundsController from './PlayerSoundsController';
import * as Tone from 'tone';



export default function ({ daw, projectList, setProjectList, timelineCursor, currentTime, setTimeLineCursor, showPianoRoll, setShowPianoRoll, setKeyframes, keyframes = [], setCurrentFrame = null, setCurrentLayer = null, onSingleNote = () => { }, pianoRollPlayChord = () => { }, pianoRollPlayFullSong = () => { } }) {
    var notes = ZotUtil.generatePianoKeyAccess()
    const gridSize = 20; // Grid size in pixels
    const FPS = 24;
    const [gridPosition, setGridPosition] = useState({ x: 0, y: 0 });
    const [selectedLayer, setSelectedLayer] = useState(0);
    const [selectedFrame, setSelectedFrame] = useState(null);
    const scenePlayer = useRef(null);
    const [iSSceneDragPlay, setIsSceneDragPlay] = useState(false);
    const [sceneDurationSecond, setSceneDurationSecond] = useState(0);
    const [currentTimeSeek, setCurrentTimeSeek] = useState(0);
    const [labelDialog, setLabelDialog] = useState({ data: {}, id: null, show: false });
    const [isResizing, setIsResizing] = useState(false);
    const [showNoteDialog, setShowNoteDialog] = useState(false);
    const [notesText, setNotesText] = useState("D#MAJ-DMIN");
    var keyframesTypes = [
        { key: 'Frame', value: "frames" },
        { key: 'Action', value: "Action" }
    ]


    //GET THE SCENE GRID X AND Y COORD
    const handleMouseMove = (event) => {
        const containerRect = event.currentTarget.getBoundingClientRect();
        const mouseX = event.clientX - containerRect.left;
        const mouseY = event.clientY - containerRect.top;

        const gridX = Math.floor(mouseX / gridSize);
        const gridY = Math.floor(mouseY / gridSize);
        setGridPosition({ x: gridX, y: gridY });

    };

    //ADD A KEYFRAME
    function addKeyFrameDoubleClick() {
        // alert("d")
        // if (selectedLayer !== null && selectedLayer === gridPosition.y) {
        keyframes[selectedLayer].frames.push({
            time: ZotUtil.stepsToneJsTime(gridPosition.x),
            duration: ZotUtil.stepsToneJsTime(1),
            note: notes[gridPosition.y],
        })
        keyframes[selectedLayer].maxTime = ZotUtil.getLargestTimeAndDuration(keyframes[selectedLayer].frames)
        setKeyframes([...keyframes])
        console.log(keyframes[0])
        // }
        // onSingleNote(gridPosition.y, null)
        daw.playSingleNote(keyframes[selectedLayer].sound, notes[gridPosition.y].toLowerCase())


    }


    //DELETE KEYFRAME
    const handleRightClick = (event, layerNumber, frameIndex) => {
        event.preventDefault(); // Prevent the default context menu from appearing
        delete keyframes[layerNumber].frames[frameIndex]
        setKeyframes([...keyframes])
    };





    useEffect(() => {
        // Scroll to 50% of the page height
        const scrollToPosition = () => {
            const scrollHeight = document.documentElement.scrollHeight;
            const scrollTop = scrollHeight * 0.2;
            window.scrollTo({
                top: scrollTop,
                // behavior: 'smooth', // Smooth scroll
            });
        };
        scrollToPosition();
    }, []);

    const numBars = 8; // Number of bars
    const keysPerBar = 12; // Number of keys per bar
    const [refreshNote, setRefreshNote] = useState(false);
    const [notesInfo, setNoteInfo] = useState({ name: "", note: "" });
    const [soundRefs, setSoundRefs] = useState({});
    const pianoBarsRef = useRef(null);
    const pianoKeysRef = useRef(null);

    useEffect(() => {
        const pianoBarsElement = pianoBarsRef.current;
        var c = document.getElementById("music-container")
      
        const syncScroll = () => {  console.log( c.scrollLeft)
            if (pianoBarsElement) {
                pianoBarsElement.scrollLeft = c.scrollLeft;
            } if (pianoKeysRef.current) {
                pianoKeysRef.current.scrollTop = c.scrollTop;
            }
        };




        c.addEventListener('scroll', syncScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            c.removeEventListener('scroll', syncScroll);
        };
    }, []);



    return (
        <>

            <Dialog visible={showNoteDialog} onHide={e => setShowNoteDialog(false)} style={{ width: "500px" }} >
                <InputTextarea value={notesText} onChange={e => {
                    setNotesText(e.target.value)
                    var textToN = ZotUtil.parseTextToChords(e.target.value)
                    var chord = ZotUtil.transformNotesToChords(textToN[0])
                    var bass = ZotUtil.transformNotesToChords(textToN[1])
                    keyframes[selectedLayer].frames = chord;
                    projectList[1].frames = bass;
                    projectList[0].frames = chord;
                    setProjectList([...projectList])
                    // setKeyframes([...keyframes])
                }} className='w-full' />
                <Button label='Play' onClick={e => {
                    daw.play(currentTimeSeek, projectList)
                }} />
            </Dialog>
            {/* <PlayerSoundsController refresh={refreshNote} soundRefs={soundRefs} setSoundRefs={setSoundRefs} /> */}
            <Dialog header={'Label Config'} style={{ width: "500px" }} visible={labelDialog.show} onHide={e => setLabelDialog({ ...labelDialog, show: false })} >
                <div className="grid">
                    <div className="col-12">
                        Label Name
                    </div>
                    <div className="col-12">
                        <InputText value={keyframes[labelDialog?.id]?.title} onChange={e => {
                            keyframes[labelDialog?.id].title = e.target.value
                            setKeyframes([...keyframes])
                        }} className='w-full' />
                    </div>
                </div>
            </Dialog>
            <div className="full-scene-timeline-container" style={{ display: showPianoRoll ? "block" : "none" }}>
                <div className="timeline-control">
                    <div className="space">
                    </div>
                    <div className="controls">
                        <div className="group">
                            <Button label='Play' onClick={e => {
                                daw.play(currentTimeSeek, projectList)
                            }} />

                            <Button label='Fill' onClick={e => {
                                var frames = keyframes[0].frames

                                var new_frame = ZotUtil.repeatSequence(frames)
                                if (new_frame === null) {
                                    alert("Overlapping bars: cannot fit into 8 bars.")
                                } else {
                                    console.log(frames)
                                    console.log(new_frame)
                                    keyframes[0].frames = new_frame
                                    setKeyframes([...keyframes])
                                }
                                // daw.play(currentTimeSeek, projectList)
                            }} />


                            <Button label='TextToNote' onClick={e => {
                                const progressionLength = 8; // Number of chords in the progression
                                // const result = generateProgressionWithTiming(progressionLength);
                                // keyframes[0] = generateSequence(8)
                                // setKeyframes([...keyframes])
                                setShowNoteDialog(true)
                            }} />


                            <div className="duration">
                                {/* <div className="start">{sceneCurrentTimeSecond}</div> */}
                                <div className="to">-</div>
                                <div className="end">{sceneDurationSecond}</div>
                            </div>
                        </div>
                        <div className="close-button" onClick={() => setShowPianoRoll(false)}>
                            X
                        </div>
                        <div className="group">
                            {/* <img src={timelineImage} alt="" /> */}
                        </div>

                    </div>
                </div>
                <div className="piano-bars" ref={pianoBarsRef}>
                    {/* <div className="space"></div> */}
                    <div className="bars">
                        {[...Array(numBars)].map((_, barIndex) => (
                            <div style={{ left: barIndex * 16 * 20, width: 16 * 20 }} key={barIndex} className="bar">
                                {barIndex}
                            </div>
                        ))}
                    </div>

                </div>
                <div className='timeline-container' >
                    <div className='timeline' >
                        {/* <div className='labels'>
                            {keyframes.map((keyframe, index) => <div className={`label timeline-label ${selectedLayer === index && "label-active"}`} key={index}
                                onClick={e => {
                                    setSelectedLayer(index)
                                    setSelectedFrame(null)
                                }}>{keyframe.title}
                                <div className="buttons">
                                    <Button icon='pi pi-trash' className='p-button-rounded p-button-danger p-button-outlined' />
                                    <Button icon='pi pi-pencil'
                                        onClick={e => setLabelDialog({ ...labelDialog, show: true, id: index, data: null })}
                                        className='p-button-rounded p-button-secondary p-button-outlined' />
                                    <Button icon='pi pi-eye' className='p-button-rounded p-button-success p-button-outlined' />
                                </div>
                            </div>)}
                        </div> */}

                        <div className="piano" ref={pianoKeysRef}>
                            {ZotUtil.generatePiano().map((key, index) => (
                                <div
                                    onClick={() => {
                                        // onSingleNote("roll", key.noteText)
                                        daw.playSingleNote(keyframes[selectedLayer].sound, key.noteText.toLowerCase())
                                    }}
                                    key={index}
                                    className={`key ${key.isBlack ? 'black' : 'white'}`}
                                >
                                    {key.noteText}
                                </div>
                            ))}
                        </div>

                        <div
                            onMouseLeave={() => {
                                // setGridPosition({ x: 0, y: 0 });
                                // setSelectedLayer(null);
                                // setSelectedFrame(null);
                            }}
                            onDoubleClick={() => {
                                addKeyFrameDoubleClick()
                            }}
                            className='keyframes'
                            onClick={() => {
                                setTimeLineCursor(gridPosition.x * gridSize)
                            }}
                            onMouseMove={handleMouseMove}
                        >
                            <div className="beat-lines">
                                {[...Array(16 * 2)].map((_, barIndex) => (
                                    <div style={{ left: barIndex * 4 * 20, }} key={barIndex} className={`line ${barIndex % 4 == 0 && 'its-a-bar'}`}>
                                    </div>
                                ))}
                            </div>

                            <TimelinePlayerCursor
                                gridSize={gridSize}
                                timeLineCursor={currentTime * 20}
                                MouseUp={() => {
                                    setIsSceneDragPlay(false)
                                }}
                                MouseDown={() => {
                                    setIsSceneDragPlay(true)
                                    // setIsPlaying(false)
                                }}
                                MouseMove={(currentFrame) => {
                                    console.log(currentFrame)
                                    setTimeLineCursor(currentFrame)
                                    var steps = ZotUtil.pixelsToFrames(currentFrame);
                                    setCurrentTimeSeek(steps)
                                }}
                            />

                            <Moveable
                                target={!isResizing ? (selectedFrame !== null ? `.hash-${selectedFrame}` : selectedFrame) : null}
                                draggable={true}
                                throttleDrag={1}
                                edgeDraggable={false}
                                startDragRotate={0}
                                throttleDragRotate={0}
                                origin={false}
                                snapContainer={".keyframes"}
                                snapHorizontalThreshold={20}
                                snapVerticalThreshold={20}
                                snappable={true}
                                keepRatio={false}
                                resizable={true}
                                throttleScale={0}
                                hideDefaultLines={true}
                                bounds={{ left: 0, right: 20 * 8 * 16, top: 0, bottom: 20 * 108 }}
                                renderDirections={["e"]}


                                snapGridWidth={20}
                                snapGridHeight={20}
                                isDisplayGridGuidelines={false}

                                onDrag={e => {
                                    e.target.style.left = `${e.left}px`;
                                    e.target.style.top = `${e.top}px`;
                                    // onSingleNote(gridPosition.y, null)
                                    const { left, width, top } = ZotUtil.getLeftTopWidth(e.target.style);
                                    // console.log(notes[top])
                                    daw.playSingleNote(keyframes[selectedLayer].sound, notes[top].toLowerCase())
                                }}
                                onRenderEnd={e => {
                                    const { left, width, top } = ZotUtil.getLeftTopWidth(e.target.style);
                                    keyframes[selectedLayer].frames[selectedFrame].note = notes[top];
                                    keyframes[selectedLayer].frames[selectedFrame].time = ZotUtil.stepsToneJsTime(left);
                                    keyframes[selectedLayer].frames[selectedFrame].duration = ZotUtil.stepsToneJsTime(width);
                                    keyframes[selectedLayer].maxTime = ZotUtil.getLargestTimeAndDuration(keyframes[selectedLayer].frames)

                                    setKeyframes([...keyframes])
                                    daw.playSingleNote(keyframes[selectedLayer].sound, notes[top].toLowerCase())
                                }}
                                onResize={e => {
                                    var size = ZotUtil.pixelsToSteps(e.width);
                                    if (size === 0) {
                                        size = 20
                                    }
                                    else {
                                        size *= 20
                                    }
                                    e.target.style.width = `${size}px`;
                                    e.target.style.transform = e.drag.transform;
                                }}


                            />


                            {
                                keyframes.map((keyframe, layerNumber) => {
                                    return keyframe?.frames?.map((frame, frameIndex) => (
                                        <strong
                                            style={{
                                                width: ZotUtil.toneJsTimeToSteps(frame.duration) * gridSize || 20,
                                                position: 'absolute',
                                                top: notes.indexOf(frame.note) * gridSize, // Adjusted to align with grid line
                                                left: ZotUtil.toneJsTimeToSteps(frame.time) * gridSize, // Adjusted to align with grid line
                                            }}
                                            className={`frame  hash-${frameIndex}  ${(selectedLayer === layerNumber && selectedFrame === frameIndex) && "strong-active"}`}
                                            onMouseEnter={e => {
                                                if (selectedLayer !== null) {
                                                    setSelectedFrame(frameIndex)
                                                }
                                            }}
                                            onClick={(event) => {
                                                daw.playSingleNote(keyframes[selectedLayer].sound, notes[gridPosition.y].toLowerCase())
                                                event.stopPropagation();
                                                const parentDiv = event.target.closest('.frame');
                                                if (selectedLayer !== null) {
                                                    setSelectedFrame(frameIndex)
                                                }
                                            }}
                                            onContextMenu={e => handleRightClick(e, layerNumber, frameIndex)}></strong>
                                    ))

                                })
                            }

                        </div>
                    </div>
                </div >
            </div>
        </>
    )
}
