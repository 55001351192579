export var hat = {
    "id": "f44bd439-030d-4813-9cad-c84ac3162766",
    "sound": "hat.wav",
    "group": "Deep_House_HAT",
    "maxTime": 127,
    autoProduce:[],
    "frames": [
        {
            "time": "0:0:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "0:1:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "0:2:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "0:3:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "1:0:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "1:1:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "1:2:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "1:3:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "2:0:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "2:1:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "2:2:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "2:3:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "3:0:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "3:1:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "3:2:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "3:3:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "4:0:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "4:1:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "4:2:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "4:3:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "5:0:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "5:1:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "5:2:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "5:3:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "6:0:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "6:1:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "6:2:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "6:3:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "7:0:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "7:1:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "7:2:2",
            "duration": "0:0:1",
            "note": "C5"
        },
        {
            "time": "7:3:2",
            "duration": "0:0:1",
            "note": "C5"
        }
    ],
    "on": false
}