export var kick =  {
    id: "oiiokjdkjd",
    sound: 'kick.wav',
    autoProduce:[],
    frames: [
      {
        "time": "0:0:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "0:1:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "0:2:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "0:3:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "0:0:2",
        "duration": "0:0:2",
        "note": "C5"
      },
      {
        "time": "1:0:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "1:1:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "1:2:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "1:3:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "2:0:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "2:0:2",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "2:1:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "2:2:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "2:3:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "3:0:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "3:1:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "3:2:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "3:3:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "4:0:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "4:0:2",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "4:1:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "4:2:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "4:3:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "5:0:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "5:1:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "5:2:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "5:3:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "6:0:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "6:0:2",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "6:1:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "6:2:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "6:3:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "7:0:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "7:1:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "7:2:0",
        "duration": "0:0:1",
        "note": "C5"
      },
      {
        "time": "7:3:0",
        "duration": "0:0:1",
        "note": "C5"
      }
    ],
    maxTime: 128,
    on: false,
  }