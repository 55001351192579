import React from 'react'

import { chord } from 'rad.js'

var callFun = function () {
  var self = {}
  self.CSSstringToJson = (string) => {
    const css_json = `{"${string
      .replace(/; /g, '", "')
      .replace(/: /g, '": "')
      .replace(";", "")}"}`;

    const obj = JSON.parse(css_json);

    const keyValues = Object.keys(obj).map((key) => {
      var camelCased = key.replace(/-[a-z]/g, (g) => g[1].toUpperCase());
      return { [camelCased]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
  }


  self.CssObjectToString = (style) => {
    return Object.keys(style).reduce((acc, key) => (
      acc + key.split(/(?=[A-Z])/).join('-').toLowerCase() + ':' + style[key] + ';'
    ), '');
  };


  // Recursive function to flatten the tree and store only id and title
  self.flattenTree = (nodes) => {
    return nodes.reduce((acc, node) => {
      // Add current node with id and title
      const { id, title } = node;
      acc.push({ id, title });

      // Recursively flatten children nodes
      if (node.children) {
        acc = acc.concat(self.flattenTree(node.children));
      }

      return acc;
    }, []);
  };

  self.findNodeById = (nodes, id) => {
    for (const node of nodes) {
      if (node.id === id) {
        return node;
      }
      if (node.children) {
        const found = self.findNodeById(node.children, id);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };


  // Recursive function to delete a node by ID
  self.deleteNodeById = (nodes, id) => {
    return nodes.reduce((acc, node) => {
      if (node.id === id) {
        // If this is the node to delete, don't include it in the result
        return acc;
      }
      if (node.children) {
        // Recursively delete in the children
        const updatedChildren = self.deleteNodeById(node.children, id);
        return [...acc, { ...node, children: updatedChildren }];
      }
      return [...acc, node];
    }, []);
  };


  //replace node , nodeID,{}
  self.replaceNodeById = (nodes, id, newNode) => {
    return nodes.map(node => {
      if (node.id === id) {
        // Replace the node with the new node object
        return { ...node, ...newNode, id: node.id }; // Ensure the ID remains the same
      }
      if (node.children) {
        // Recursively update children
        return { ...node, children: self.replaceNodeById(node.children, id, newNode) };
      }
      return node;
    });
  };

  self.extractSpecialCSSProperties = (cssString) => {
    // Initialize default values
    const defaultValues = {
      'transform-origin': '50% 50%',
      'transform': 'translate(0px,0px)',
      'margin': '0px',
      'border': 'none',
      'padding': '0px'
    };

    // Regular expressions to match properties and their values
    const regex = {
      'transform-origin': /transform-origin:\s*([^;]*)/,
      'transform': /transform:\s*([^;]*)/,
      'rotate': /rotate:\s*([^;]*)/,
      'scale': /scale:\s*([^;]*)/,
      'margin': /margin:\s*([^;]*)/,
      'border': /border:\s*([^;]*)/,
      'padding': /padding:\s*([^;]*)/
    };

    // Extract properties using regular expressions
    const result = {};
    for (const [key, re] of Object.entries(regex)) {
      const match = cssString.match(re);
      result[key] = match ? match[1].trim() : null;
    }

    // Return the extracted properties with default values where applicable
    return result;
  };


  self.secondsToFrames = (seconds, fps = 24) => {
    return Math.round(seconds * fps);
  }
  self.pixelsToFrames = (widthInPixels) => {
    const frameWidth = 20; // 1 frame = 20 pixels
    return Math.floor(widthInPixels / frameWidth);
  }
  self.frameToSecond = (seconds, fps = 24) => {
    return seconds / fps;
  }
  self.inverseNumber = (x) => {
    if (x < 0 || x > 96) {
      throw new Error("Input must be between 0 and 96 inclusive");
    }
    return 96 - x;
  }

  self.generatePiano = () => {
    const keys = [];

    const notes = [
      'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'
    ];
    notes.reverse()
    // Create keys for C9 to C0
    for (let octave = 8; octave >= 0; octave--) {
      for (const note of notes) {
        const noteText = `${note}${octave}`;
        const isBlack = note.includes('#');
        keys.push({ noteText, isBlack });
      }
    }
    return keys
  }

  self.generatePianoKeyAccess = () => {
    const keys = [];

    const notes = [
      'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'
    ];
    notes.reverse()
    // Create keys for C9 to C0
    for (let octave = 8; octave >= 0; octave--) {
      for (const note of notes) {
        const noteText = `${note}${octave}`;
        const isBlack = note.includes('#');
        keys.push(noteText);
      }
    }
    return keys
  }

  self.getNoteFromNumber = (n) => {
    function inverseNumber(x) {
      if (x < 0 || x > 96) {
        throw new Error("Input must be between 0 and 96 inclusive");
      }
      return 96 - x;
    }
    var number = inverseNumber(n)

    const notes = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
    const totalNotes = notes.length;

    if (number < 0 || number > 96) {
      return "Invalid number. Please enter a number between 0 and 96.";
    }

    // Determine the octave and the note within the octave
    const octave = Math.floor(number / totalNotes);
    const noteIndex = number % totalNotes;

    // Construct the note string
    const note = notes[noteIndex] + octave;

    return note;
  }
  self.getLeftTopWidth = (style) => {
    const left = parseFloat(style.left.replaceAll("px", "") || 0);
    const top = parseFloat(style.top.replaceAll("px", "") || 0);
    const width = parseFloat(style.width.replaceAll("px", "") || 0);
    return { left: self.pixelsToFrames(left), top: self.pixelsToFrames(top), width: self.pixelsToFrames(width) }
  }

  self.getRealPositionFromCss = (cssString) => {
    const style = {};
    cssString.split(';').forEach((rule) => {
      const [key, value] = rule.split(':').map((str) => str.trim());
      if (key && value) {
        style[key] = value;
      }
    });

    const left = parseFloat(style.left || 0);
    const top = parseFloat(style.top || 0);
    const width = parseFloat(style.width || 0);
    const transform = style.transform;

    let translateX = 0;
    let translateY = 0;

    if (transform && transform !== 'none') {
      const match = /translate\(([^,]+)px,\s*([^,]+)px\)/.exec(transform);
      if (match) {
        translateX = parseFloat(match[1]);
        translateY = parseFloat(match[2]);
      }
    }

    const realLeft = self.pixelsToFrames(left + translateX);
    const realTop = self.pixelsToFrames(top + translateY);

    return { left: realLeft, top: top, width, width };
  }


  function groupByStart(data) {
    const grouped = {};

    data.forEach(item => {
      if (!grouped[item.start]) {
        grouped[item.start] = {
          start: item.start,
          end: item.end,
          chords: []
        };
      }
      grouped[item.start].chords.push(item.note);
      // Update the end value if the current item's end is greater
      if (item.end > grouped[item.start].end) {
        grouped[item.start].end = item.end;
      }
    });

    return Object.values(grouped);
  }
  function calculateSilence(data) {
    const sortedData = [...data].sort((a, b) => a.start - b.start);
    const silences = [];

    // Check for silence before the first chord
    if (sortedData.length > 0 && sortedData[0].start > 0) {
      silences.push({
        start: 0,
        end: sortedData[0].start
      });
    }

    for (let i = 0; i < sortedData.length - 1; i++) {
      const currentEnd = sortedData[i].end;
      const nextStart = sortedData[i + 1].start;

      if (nextStart > currentEnd) {
        silences.push({
          start: currentEnd,
          end: nextStart
        });
      }
    }

    // Check for silence after the last chord until 128
    if (sortedData.length > 0 && sortedData[sortedData.length - 1].end < 128) {
      silences.push({
        start: sortedData[sortedData.length - 1].end,
        end: 128
      });
    }

    return silences;
  }

  self.mergeChordsAndSilences = (data) => {
    const groupedChords = groupByStart(data);
    const silences = calculateSilence(data);

    // Combine chords and silences into one array
    const combined = [];

    let i = 0; // Index for groupedChords
    let j = 0; // Index for silences

    while (i < groupedChords.length || j < silences.length) {
      if (i < groupedChords.length && (j >= silences.length || groupedChords[i].start <= silences[j].start)) {
        combined.push({
          ...groupedChords[i],
          type: 'chord'
        });
        i++;
      } else if (j < silences.length) {
        combined.push({
          start: silences[j].start,
          end: silences[j].end,
          chords: [],
          type: 'silence'
        });
        j++;
      }
    }

    return combined;
  }



  self.pixelsToSteps = (pixels) => {
    return Math.floor(pixels / 20);
  }


  self.stepsToneJsTime = (steps) => {
    var stepsPerBeat = 4;
    var beatsPerMeasure = 4
    // Calculate the total number of beats and measures from steps
    const totalBeats = steps / stepsPerBeat;
    const measures = Math.floor(totalBeats / beatsPerMeasure);
    const beats = Math.floor(totalBeats % beatsPerMeasure);
    const subbeats = Math.round((totalBeats - Math.floor(totalBeats)) * stepsPerBeat);

    // Format to measure:beat:subbeat
    return `${measures}:${beats}:${subbeats}`;
  }
  self.toneJsTimeToSteps = (timeStr) => {
    var stepsPerBeat = 4;
    var beatsPerMeasure = 4
    // Split the input time string into measure, beat, and subbeat
    const [measureStr, beatStr, subbeatStr] = timeStr.split(':').map(Number);

    // Calculate total beats from measure, beat, and subbeat
    const totalBeats = (measureStr * beatsPerMeasure) + beatStr + (subbeatStr / stepsPerBeat);

    // Calculate total steps
    const totalSteps = totalBeats * stepsPerBeat;

    return totalSteps;
  }

  self.repeatSequence = (sequence, bars = 8) => {
    var stepsPerBeat = 4;
    var beatsPerMeasure = 4
    const totalBars = 8;
    const sequenceLengthInBars = Math.ceil(sequence.reduce((maxTime, entry) => {
      const endTime = self.toneJsTimeToSteps(entry.time) + self.toneJsTimeToSteps(entry.duration);
      return Math.max(maxTime, endTime);
    }, 0) / (stepsPerBeat * beatsPerMeasure));

    if (bars % sequenceLengthInBars !== 0) {
      if (sequenceLengthInBars * 2 > totalBars) {
        // throw new Error('Overlapping bars: cannot fit into 8 bars.');
        return null;
      }
      bars = Math.min(bars, totalBars);  // Adjust to fit within 8 bars
    }

    const repeatCount = bars / sequenceLengthInBars;

    const extendedSequence = [];
    for (let i = 0; i < repeatCount; i++) {
      sequence.forEach(entry => {
        const newEntry = {
          ...entry,
          time: self.stepsToneJsTime(self.toneJsTimeToSteps(entry.time) + i * sequenceLengthInBars * stepsPerBeat * beatsPerMeasure)
        };
        extendedSequence.push(newEntry);
      });
    }

    return extendedSequence;
  };



  self.getLargestTimeAndDuration = (dataArray) => {
    function convertToSeconds(timeString) {
      const [hours, minutes, seconds] = timeString.split(':').map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    }
    let maxObject = null;
    let maxTime = -1;
    let maxDuration = -1;

    for (const data of dataArray) {
      const timeInSeconds = convertToSeconds(data.time);
      const durationInSeconds = convertToSeconds(data.duration);

      if (timeInSeconds > maxTime || (timeInSeconds === maxTime && durationInSeconds > maxDuration)) {
        maxTime = timeInSeconds;
        maxDuration = durationInSeconds;
        maxObject = data;
      }
    }

    if (maxObject === null) {
      return 0
    }
    else {
      var duration = self.toneJsTimeToSteps(maxObject["duration"])
      var time = self.toneJsTimeToSteps(maxObject["time"])
      return duration + time;
    }
  }


  self.groupPacksBy = (items, key) => {
    return items.reduce((result, item) => {
      // Get the group name based on the specified key
      const groupKey = item[key];

      // If the group doesn't exist in the result, create an empty array
      if (!result[groupKey]) {
        result[groupKey] = [];
      }

      // Push the current item into the appropriate group
      result[groupKey].push(item);

      return result;
    }, {}); // Initial value is an empty object
  }


  // Function to shift the timing of notes
  self.shiftNoteTiming = (frames, shiftValue) => {
    console.log(shiftValue)
    // Convert steps to ticks and vice versa
    const stepsPerBeat = 4;
    const beatsPerMeasure = 4;
    const measuresPerShift = 8; // 8 bars per shift



    // Calculate total steps to shift (8 bars worth of steps per shiftValue)
    const stepsPerShift = measuresPerShift * beatsPerMeasure * stepsPerBeat;

    // Apply timing shift
    const updatedFrames = frames.map(frame => {

      try {
        const currentSteps = self.toneJsTimeToSteps(frame.time);
        const newSteps = currentSteps + (shiftValue * stepsPerShift);
        return {
          ...frame,
          time: self.stepsToneJsTime(newSteps)
        };
      } catch (error) {
        return { time: 0, note: '' }
      }
    });

    // Return a new rhodes object with the updated frames
    return updatedFrames
  }

  self.getBassline = (chords) => {
    var bass = new window.music21.chord.Chord((chords)).bass()
    console.log(bass)
    if (bass._octave <= 4)
      return `${bass.name}${3}`
    else
      return `${bass.name}${bass._octave}`
  }
  self.parseTextToChords = (text = "d#min-amaj", tone = 4) => {
    var chordsList = text.trim().replaceAll(" ", "").split("-")?.filter(e => e.length > 0) || []
    var chordsStore = [];
    var bassline = [];
    chordsList.forEach(c => {
      try {
        var cc = chord(c, tone)
        var bass = self.getBassline(cc.join(" "))
        // console.log(bass)
        bassline.push([bass])
        chordsStore.push(cc)
      } catch (error) {
        // alert("invalid chord " + c)
        return [[], []]
      }
    });

    return [chordsStore, bassline]
  }



  // Function to transform the notes array into the desired format
  self.transformNotesToChords = (notesList) => {
    const result = [];
    notesList.forEach((notes, measureIndex) => {
      notes.forEach(note => {
        result.push({
          time: `${measureIndex}:0:0`,
          duration: "1:0:0",  // Set duration to 1 step
          note: note
        });
      });
    });
    return result;
  };




  self.EVENT_LABEL_RENAME = "EVENT_LABEL_RENAME"
  self.EVENT_LABEL_DELETE = "EVENT_LABEL_DELETE"
  self.EVENT_PLAYER_PLAY = "EVENT_PLAY"
  self.EVENT_PLAYER_PAUSE = "EVENT_PAUSE"
  self.EVENT_PLAYER_PLAY_BY_DRAG = "EVENT_PLAY_BY_DRAG"

  return self;
}

export var ZotUtil = callFun()