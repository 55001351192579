export var rhodes = {
    "id": "80837349-58b2-4c92-9bb6-d6511dd18faf",
    "sound": "rhodes.wav",
    "group": "Deep_House_Piano",
    "maxTime": 128,
    autoProduce: [],
    "frames": [
        {
            "time": "0:0:0",
            "duration": "2:0:0",
            "note": "A4"
        },
        {
            "time": "0:0:0",
            "duration": "2:0:0",
            "note": "F4"
        },
        {
            "time": "0:0:0",
            "duration": "2:0:0",
            "note": "D4"
        },
        {
            "time": "0:0:0",
            "duration": "2:0:0",
            "note": "A#3"
        },
        {
            "time": "2:0:0",
            "duration": "2:0:0",
            "note": "E5"
        },
        {
            "time": "2:0:0",
            "duration": "2:0:0",
            "note": "C5"
        },
        {
            "time": "2:0:0",
            "duration": "2:0:0",
            "note": "A4"
        },
        {
            "time": "2:0:0",
            "duration": "2:0:0",
            "note": "F4"
        },
        {
            "time": "4:0:0",
            "duration": "2:0:0",
            "note": "A#3"
        },
        {
            "time": "4:0:0",
            "duration": "2:0:0",
            "note": "D4"
        },
        {
            "time": "4:0:0",
            "duration": "2:0:0",
            "note": "F4"
        },
        {
            "time": "4:0:0",
            "duration": "2:0:0",
            "note": "A4"
        },
        {
            "time": "6:0:0",
            "duration": "2:0:0",
            "note": "F4"
        },
        {
            "time": "6:0:0",
            "duration": "2:0:0",
            "note": "A4"
        },
        {
            "time": "6:0:0",
            "duration": "2:0:0",
            "note": "C5"
        },
        {
            "time": "6:0:0",
            "duration": "2:0:0",
            "note": "E5"
        },
        {
            "time": "0:0:0",
            "duration": "2:0:0",
            "note": "G3"
        },
        {
            "time": "2:0:0",
            "duration": "2:0:0",
            "note": "D4"
        },
        {
            "time": "4:0:0",
            "duration": "2:0:0",
            "note": "G3"
        },
        {
            "time": "6:0:0",
            "duration": "2:0:0",
            "note": "D4"
        }
    ],
    "on": false
}