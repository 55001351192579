var IP = "http://localhost:50000"

if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
  // Running locally
  IP = "http://localhost:50000";
} else {
  IP = "";
}


export { IP }